<template>
  <div>
    <AppTable
      v-if="rows"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: kycItems.limit,
        count: kycItems.count,
        page: kycItems.page
      }"
      :rows="rows"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col
            v-if="isFilteredByUser"
            class="mb-1"
            md="3"
            xl="3"
          >
            <UserFilter
              :search-action="fetchAdminUsers"
              label="name"
              placeholder="Select responsible"
              title="Responsible"
              @change="onResponsibleIdChange"
            />
          </b-col>
          <b-col
            v-else
            class="mb-1"
            md="3"
            xl="3"
          >
            <b-form-group>
              <label class="mr-1">TG Username</label>
              <b-form-input
                v-model="tgUsername"
                placeholder="TG Username"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="mb-1"
            md="2"
            xl="2"
          >
            <b-form-group>
              <label for="datepicker-placeholder">Status</label>
              <b-form-select
                v-model="selectStatus"
                :options="statusOptions"
                @change="changeStatus"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1"
            md="2"
            xl="2"
          >
            <b-form-group>
              <label for="datepicker-placeholder">Start date</label>
              <b-form-datepicker
                v-model="dateFrom"
                close-button
                local="en"
                placeholder="Choose a min date"
                reset-button
                today-button
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1"
            md="2"
            xl="2"
          >
            <b-form-group>
              <label for="datepicker-placeholder">End date</label>
              <b-form-datepicker
                v-model="dateTo"
                close-button
                local="en"
                placeholder="Choose a max date"
                reset-button
                today-button
              />
            </b-form-group>
          </b-col>
          <b-col
            class="mb-1"
            md="2"
            xl="2"
          >
            <b-form-group>
              <label for="datepicker-placeholder">KYC Level</label>
              <b-form-select
                v-model="selectLevel"
                :options="levelOptions"
                @change="changeLevel"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="mb-1 mt-auto pb-1"
            md="1"
            xl="1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon
                icon="SearchIcon"
                size="18"
              />
            </b-button>
          </b-col>

          <!--          <b-col-->
          <!--            class="mb-1"-->
          <!--            md="3"-->
          <!--            xl="3"-->
          <!--          >-->
          <!--            <b-form-group>-->
          <!--              <label class="mr-1">ResponsibleId</label>-->
          <!--              <b-form-input-->
          <!--                v-model="responsibleId"-->
          <!--                placeholder="Responsible Id"-->
          <!--                type="text"-->
          <!--              />-->
          <!--            </b-form-group>-->
          <!--          </b-col>-->
          <b-col
            v-if="isFilteredByUser"
            class="mb-1"
            md="3"
            xl="3"
          >
            <b-form-group>
              <label class="mr-1">TG Username</label>
              <b-form-input
                v-model="tgUsername"
                placeholder="TG Username"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="mb-1"
            md="3"
            xl="3"
          >
            <b-form-group>
              <label class="mr-1">TG Id</label>
              <b-form-input
                v-model="tgId"
                placeholder="tg Id"
                type="text"
              />
            </b-form-group>
          </b-col>

        </b-row>
      </template>

      <template #default="{ column, row, formattedRow }">
        <div
          v-if="column.field === 'user'"
        >
          <div v-if="row.user.telegramUsername">
            {{ row.user.telegramUsername }}
          </div>
          <b-badge
            v-if="row.user.telegramId"
            class="text-white"
            variant="secondary"
          >
            {{ row.user.telegramId || '' }}
          </b-badge>
        </div>

        <!--        updatedByUser-->
        <b-avatar
          v-else-if="column.field === 'updatedByUser'"
          v-show="!row.updatedByUser"
          size="20"
          variant="light-success"
        >
          <feather-icon
            icon="CheckCircleIcon"
            size="18"
          />
        </b-avatar>
        <span
          v-else-if="column.field === 'responsible'"
          class="flex-center-align "
        >
          <feather-icon
            :icon="row.responsible ? 'UserIcon' : 'InfoIcon'"
            size="18"
          />
          {{ row.responsible ? row.responsible.name : 'Need an officer' }}
        </span>

        <span
          v-else-if="column.field === 'status'"
          class="flex-center-align"
        >
          <b-badge
            :variant="row.status.color"
            class="text-white"
          >
            {{ row.status.text || '' }}
          </b-badge>
        </span>
        <span
          v-else-if="column.field === 'level'"
          class="flex-center-align"
        >
          <KycLevel :level="row.level" />
        </span>
        <span
          v-else-if="column.field === 'actions'"
          class="d-flex flex-row"
        >
          <b-button
            class="text-left"
            target="_blank"
            variant="primary"
            @click="onClickView(row)"
          >
            View
          </b-button>
          <template v-if="isAction">
            <b-button
              v-if="!row.responsible"
              class="text-left ml-1"
              type="button"
              variant="light"
              @click="onClickToDo(row)"
            >
              To Do
            </b-button>
            <b-button
              v-else
              class="text-left text-nowrap ml-1"
              variant="light"
            >
              In Progress
            </b-button>
          </template>

        </span>

        <!-- default render cell -->
        <span
          v-else
          class="flex-center-align"
        >
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapActions, mapGetters } from 'vuex'
import waitRequest from '@/mixins/waitRequest'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'
import columns from './config/tableConfig'
import AppTable from '@/components/AppTable.vue'
import { formatLongString, formattedDate } from '@/tools/util'
import { kycLevelTypes, kycStatusColors, kycStatusTypes } from '@/views/apps/kyc/config/kycTypes'
import doCopy from '@/mixins/doCopy'
import UserFilter from '@/components/containers/UserFilter.vue'
import KycLevel from '@/views/apps/kyc/components/level.vue'

export default {
  name: 'KycAll',
  components: {
    KycLevel,
    UserFilter,
    BFormDatepicker,
    BAvatar,
    BBadge,
    AppTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, checkErrorsAlert, doCopy],
  data() {
    return {
      dateTo: '',
      dateFrom: '',
      columnsAll: columns,
      kycStatusColors,
      tgUsername: '',
      tgId: '',
      responsibleId: '',
      selectStatus: '',
      selectLevel: '',
      statusOptions: ['', ...Object.keys(kycStatusTypes)],
      levelOptions: ['', ...Object.values(kycLevelTypes)],
      kycItems: {
        items: [],
        page: 1,
        limit: 30,
        count: 0,
      },
      isFilteredByUser: true,
      isViewUser: false,
    }
  },
  computed: {
    ...mapGetters({
      listOwnerPermissions: 'users/listOwnerPermissions',
    }),
    columns() {
      const result = this.$route.name === 'kyc-progress' ? this.columnsAll : this.columnsAll.filter(column => column.field !== 'updatedByUser')
      if (!this.isViewUser) {
        return result.filter(column => column.field !== 'actions')
      }
      return result
    },
    isAction() {
      return this.$route.name === 'kyc-new'
    },
    // ...mapGetters({
    //   transactions: 'transactions/transactions',
    // }),

    rows() {
      return this.kycItems.items?.map(item => {
        const submittedAt = item.submittedAt ? this.formattedDate(item.submittedAt, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }) : ''
        const status = {
          text: item.status,
          color: this.kycStatusColors[item.status],
        }
        return {
          ...item,
          status,
          submittedAt,
        }
      })
    },
  },
  watch: {
    $route() {
      this.firstInit()
    },
  },

  mounted() {
    this.listOwnerPermissions.forEach(item => {
      // if (item === 'admin-user-page') {
      //   this.isFilteredByUser = true
      // } else

      if (item === 'kyc-application-view') {
        this.isViewUser = true
      }
    })
    this.firstInit()
  },
  methods: {
    onResponsibleIdChange(value) {
      this.responsibleId = value ? value?.id : ''
    },
    async onClickToDo(row) {
      const data = {
        id: row.id,
        responsibleId: this.$store.getters['auth/admin'].id,
      }
      await this.setResponsible(data)
      this.firstInit()
    },
    firstInit() {
      let status = null
      if (this.$route.name === 'kyc-new') {
        status = 'new'
      } else if (this.$route.name === 'kyc-progress') {
        status = 'in progress'
      } else if (this.$route.name === 'kyc-rejected') {
        status = 'reject'
      } else if (this.$route.name === 'kyc-verified') {
        status = 'verified'
      }

      this.setStatus()

      const query = this.createQueryConfig({ page: 1, status: kycStatusTypes[status] })
      this.getKycItems(query)
    },
    onClickView(row) {
      const url = `/kyc/${row.id}`
      window.open(url, '_blank')
      // this.$router.push(url)
    },
    formattedDate,
    formatLongString,

    ...mapActions({
      fetchAdminUsers: 'adminUser/fetchAdminUsers',
      fetchTable: 'kyc/fetchTable',
      setResponsible: 'kyc/setResponsible',
    }),

    initState() {
      const query = this.createQueryConfig()

      this.getKycItems(query)
    },

    search() {
      const query = this.createQueryConfig({
        page: 1,
      })

      return this.getKycItems(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })

      return this.getKycItems(query)
    },

    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })

      return this.getKycItems(query)
    },

    changeType(type) {
      const query = this.createQueryConfig({ type })

      return this.getKycItems(query)
    },

    setStatus() {
      switch (this.$route.name) {
        case 'kyc-all':
          this.selectStatus = ''
          break
        case 'kyc-new':
          this.selectStatus = 'new'
          break
        case 'kyc-progress':
          this.selectStatus = 'in progress'
          break
        case 'kyc-rejected':
          this.selectStatus = 'reject'
          break
        case 'kyc-verified':
          this.selectStatus = 'verified'
          break
        default:
          this.selectStatus = ''
      }
    },
    changeLevel(_level) {
      const level = kycLevelTypes[_level]

      const query = this.createQueryConfig({ level })
      return this.getKycItems(query)
    },
    changeStatus(_status) {
      switch (_status) {
        case '':
          this.$router.replace({ name: 'kyc-all' })
          break
        case 'new':
          this.$router.replace({ name: 'kyc-new' })
          break
        case 'in progress':
          this.$router.replace({ name: 'kyc-progress' })
          break
        case 'reject':
          this.$router.replace({ name: 'kyc-rejected' })
          break
        case 'verified':
          this.$router.replace({ name: 'kyc-verified' })
          break
        default:
          this.$router.replace({ name: 'kyc-all' })
      }
      const status = kycStatusTypes[_status]
      const query = this.createQueryConfig({ status })
      return this.getKycItems(query)
    },

    createQueryConfig(config) {
      const status = kycStatusTypes[this.selectStatus]
      return {
        level: this.selectLevel || null,
        page: this.kycItems.page,
        limit: this.kycItems.limit,
        dateFrom: this.dateFrom || null,
        dateTo: this.dateTo || null,
        tgUsername: this.tgUsername || null,
        responsibleId: this.responsibleId || null,
        tgId: this.tgId || null,

        status,
        ...config,
      }
    },

    getKycItems(query) {
      return this.waitRequest(async () => {
        const { data } = await this.fetchTable(query).catch(this.checkErrorsAlert)
        const limit = query.limit || 30
        const count = data?.total || 0
        if (data) {
          // this.kycItems = data
          this.kycItems = {
            items: data.items,
            page: data.page,
            limit,
            count,
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-good-table.scss';
legend {
  font-size: 0.857rem !important;
  font-weight: 600;
}
</style>

import { isString } from 'lodash'
import { toFlatObject } from '@/tools/helpers'

export default {
  methods: {
    checkErrors(error) {
      const errorResponse = error?.response
      const statusVariants = {
        errors: 422,
        badRequest: 400,
        notFound: 404,
        permission: 403,
        notAllowed: 405,
        serverError: 500,
        manyRequests: 429,
        conflict: 409,
        unauthorized: 401,
        toLargeContent: 413,
      }

      const errorObject = errorResponse?.data
      const status = errorResponse?.status
      const isAbstractMessage = isString(errorObject?.message)

      switch (status) {
        case statusVariants.permission:
        case statusVariants.badRequest:
        case statusVariants.errors:
          if (isAbstractMessage) this.showErrorToast(errorObject)
          else this.addErrors(errorObject)
          break

        case statusVariants.conflict:
        case statusVariants.manyRequests:
        case statusVariants.notAllowed:
        case statusVariants.unauthorized:
        case statusVariants.toLargeContent:
          this.showErrorAlert(errorObject)
          break

        case statusVariants.notFound:
          this.$router.push('/error-404')
          break

        case statusVariants.serverError:
          this.showErrorAlert(errorObject)
          break

        default:
          throw error
      }
    },

    addErrors(errorObject, form) {
      const errors = errorObject.message

      Object.keys(errors).forEach(el => {
        const messages = Object.values(errors[el]).join(', ')

        this.addError(el, messages)
      })

      if (form) this.$nextTick(() => this.scrollToError(form))
    },

    registerValidator(validator) {
      this.validator = validator
    },

    addError(fieldName, message) {
      this.validator.setErrors({ [fieldName]: message })
    },

    checkIsValidForm() {
      return this.validator.validate()
    },

    showErrorToast(errorObject) {
      this.$bvToast.toast(errorObject.message, {
        title: `${errorObject.error || ''} ${errorObject.statusCode}`,
        variant: 'danger',
        autoHideDelay: 30000,
        toaster: 'b-toaster-bottom-right',
        solid: true,
      })
    },

    showErrorAlert(errorObject) {
      this.$swal({
        title: `${errorObject.error} ${errorObject.statusCode}`,
        text: `${errorObject.message}`,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },

    haveEmptyField(object) {
      const flatObject = toFlatObject(object)
      return Object.values(flatObject).some(item => typeof item !== 'number' && !item)
    },
  },
}

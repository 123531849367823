<template>
  <b-form-group>
    <label
      v-if="title"
      for="datepicker-placeholder"
    >{{ title }}</label>
    <v-select
      v-model="selectUser"
      v-b-tooltip.focus.v-primary
      :clearable="!required"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :label="label"
      :options="userOptionsList"
      :placeholder="placeholder"
      title="Start writing to see options"
      variant="outline-primary"
      @search="searchUser"
    />
  </b-form-group>
</template>

<script>
import vSelect from 'vue-select'
import { BFormGroup, VBTooltip } from 'bootstrap-vue'
import { debounce } from 'lodash'
import store from '@/store'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

export default {
  name: 'UserFilter',
  components: {
    vSelect,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [waitRequest, validationError],
  props: {
    searchAction: {
      type: Function,
      default: () => {
      },
    },
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'name',
    },
    placeholder: {
      type: String,
      default: 'User',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectUser: null,
      select: null,
      userOptions: [''],
      searchedUserList: [],
    }
  },
  computed: {
    userOptionsList() {
      return this.searchedUserList
    },
    direction() {
      return store.state.appConfig.isRTL
    },
  },

  watch: {
    selectUser() {
      this.$emit('change', this.selectUser)
      this.$emit('input', this.selectUser)
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    setManual(value) {
      console.log(value)
      this.searchedUserList = [value]
      this.selectUser = value
    },
    async initState() {
      this.waitRequest(() => this.searchAction()
        .then(response => {
          this.searchedUserList = response?.items || []
        })
        .catch(this.checkErrorsAlert))
    },

    getUser() {
      return this.waitRequest(() => this.searchAction().catch(this.checkErrors))
    },

    searchUser: debounce(function (search) {
      return this.waitRequest(() => this.searchAction({ search })
        .then(response => {
          console.log(response)
          this.searchedUserList = response?.items || []
        })
        .catch(this.checkErrorsAlert))
    }, 500),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

export const kycStatusTypes = {
  new: ['new'],
  'in progress': ['in_progress', 'pending', 'retry', 'unverified'],
  verified: ['verified'],
  reject: ['reject'],
}
export const kycLevelTypes = {
  ADVANCED: 'advanced',
  UNLIMITED: 'unlimited',
}
export const kycStatusColors = {
  'Not found': 'secondary',
  new: 'secondary',
  in_progress: 'warning',
  pending: 'warning',
  retry: 'warning',
  unverified: 'warning',
  verified: 'success',
  reject: 'danger',
}
export const kycLevelColors = {
  unlimited: 'primary',
  advanced: 'primary',
}
export const kucSections = [
  {
    short: 'Proof of address',
    full: 'Proof of address (PoF)',
    api: 'poa',
    description: 'Proof of address (PoF) is a document that confirms where client lives. ',
    key: 'proofOfAddress',
    note: 'Ensure that the individual’s facial recognition data matches the photo in the submitted identity document and vice versa. Any discrepancies between the two may require additional verification or lead to the rejection of the document.',
  },
  {
    short: 'Source of funds',
    full: 'Source of funds (SoF)',
    api: 'sof',
    description: 'The source of funds (SOF) is the origin of a person\'s money or financial assets.',
    key: 'sourceOfFund',
    note: 'Ensure that the individual’s facial recognition data matches the photo in the submitted identity document and vice versa. Any discrepancies between the two may require additional verification or lead to the rejection of the document.',
  },
  {
    short: 'Identity document',
    full: 'Identity document',
    description: 'An identity document is a document proving a person\'s identity. It can be passport, visa, ID card, driving license.',
    key: 'id',
    api: 'id',
    note: 'Ensure that the individual’s facial recognition data matches the photo in the submitted identity document and vice versa. Any discrepancies between the two may require additional verification or lead to the rejection of the document.',
  },
  {
    short: 'Facial recognition',
    full: 'Facial recognition',
    description: 'Facial recognition is a system that identifies or verifies a person’s identity by analyzing and comparing their facial features.',
    key: 'liveness',
    note: 'Ensure that the individual’s facial recognition data matches the photo in the submitted identity document and vice versa. Any discrepancies between the two may require additional verification or lead to the rejection of the document.',
  },
  {
    short: 'Occupation',
    full: 'Occupation details',
    description: 'Occupation is a person\'s usual or principal work or business.',
    key: 'occupation',
    note: 'Ensure that the individual’s facial recognition data matches the photo in the submitted identity document and vice versa. Any discrepancies between the two may require additional verification or lead to the rejection of the document.',
  },
  {
    short: 'CDD',
    full: 'Customer Due Diligence (CDD)',
    api: 'cdd',
    description: 'The procedure for a comprehensive check of the client using various databases, as well as his connections and media background.',
    key: 'cdd',
    note: 'Ensure that the individual’s facial recognition data matches the photo in the submitted identity document and vice versa. Any discrepancies between the two may require additional verification or lead to the rejection of the document.',
  },
]

export const kycData = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}

export const KycRejectReasons = {
  id: [
    'highRiskCountry',
  ],
  liveness: [
    'maskAttack',
    'lowPrediction',
    'faceMismatch',
  ],
  sourceOfFund: [
    'blurredDocument',
    'missingInformation',
    'tamperedDocument',
    'invalidDocumentType',
    'documentExpired',
    'nameMismatch',
    'nonEnglishWithoutTranslation',
    'incompleteDeclaration',
    'unsignedOrIncompleteDocument',
    'missingIncomeDetails',
  ],
  proofOfAddress: [
    'blurredDocument',
    'missingInformation',
    'documentNotInApplicantsName',
    'documentExpired',
    'tamperedDocument',
    'invalidDocumentType',
    'nameOrAddressMismatch',
    'nonEnglishWithoutTranslation',
    'utilityBillNotInApplicantsName',
    'outdatedBill',
    'unsignedAgreement',
    'noRentalDates',
  ],
  cdd: [
    'cdd alert',
  ],
}

// 'kyc-page' - просмотр таблиц
// 'kyc-dashboard' - просмотр дашборда
// 'kyc-application-view' - просмотр заявки
// 'kyc-profile-view' - просмотр профиля
// 'kyc-review' - апрув реджект чексов и всей заявки
// 'kyc-download-pdf' - загрузка пдф
// 'kyc-set-responsible' - назначить кого-то
// 'kyc-change-status' - изменить апрув/реджект чекса и всей заявки
// 'kyc-view-photo' - просмотр фото документов

export default {
  data() {
    return {
      requestInProgress: false,
    };
  },
  methods: {
    waitRequest(callback) {
      if (this.requestInProgress) return;

      this.requestInProgress = true;

      return callback()?.finally(() => {
        this.requestInProgress = false;
      });
    },
  },
};

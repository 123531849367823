export default [
  {
    label: 'Application Id',
    field: 'id',
  },
  {
    label: 'TG Username / user id',
    field: 'user',
  },
  {
    label: 'Level',
    field: 'level',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Submitted AT',
    field: 'submittedAt',
  },
  {
    label: 'Responsible',
    field: 'responsible',
  },
  {
    label: 'Updated by User',
    field: 'updatedByUser',
  },
  {
    label: 'Actions',
    field: 'actions',
  },
]

export default {
  methods: {
    checkErrorsAlert(error) {
      const { message = '', statusCode = '', error: errorName } = error.response.data

      this.$swal({
        title: errorName,
        text: `${JSON.stringify(message)}. Status: ${statusCode}`,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    doCopy(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Text copied',
              icon: 'BellIcon',
            },
          })
        },
      ).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
  },
}
